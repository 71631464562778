.container {
    text-align: center;
    font-weight: bold;
    background: linear-gradient(to top, var(--beige) 10%, transparent 50%, var(--beige) 90%), url('../../asset/images/heading_bg.png') center/cover no-repeat;
    width: 80%;
    padding: 0 10%;
}
.txt-date {
    font-size: 10vh;
    margin-top: 3vh;
    margin-bottom: 8px;
    font-weight: 300;
    color: var(--brown)
}
.wrap-dday {
    font-size: 6vh;
    font-weight: bold;
    margin-top: 2vh;
    margin-bottom: 2vh;
}
.wrap-timer {
    font-size: 3.5vh;
    font-weight: 400;
    margin-top: 2vh;
    margin-bottom: 2vh;
    color: var(--gray)
}
