@import "@scss/utils.scss";

.container {
    .wrap-header {
        display: flex;
        flex-direction: column;
        .txt-title {
            margin-bottom: 2vh;
        }
        .txt-subtitle {
            font-weight: 300;
            font-size: 2vh;
            margin-top: 1vh;

        }
    }
    .wrap-map {
        position: relative;
        .map {
            width: 100%;
            aspect-ratio: 1 / 1;
        }
        .btn-find-way {
            border: 1px solid var(--black);
            padding: 1vh 4vh;
            text-decoration: none;
            z-index: 2;
            position: absolute;
            bottom: 3vh;
            left: 50%;
            transform: translateX(-50%);
            background-color: #fff;
            color: var(--black);
            border-radius: 3vh;
        }
    }
    .wrap-waytocome {
        margin: 2vh 0;
        line-height: 1.4;
        .txt-label {
            font-size: 2.5vh;
            font-weight: bold;
            margin-bottom: 1.5vh;
        }
    }
}