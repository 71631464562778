@import "@scss/utils.scss";

.container {
    @include txt-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .wrap-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .txt-date {
            font-size: 3vh;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .txt-time {
            font-size: 2.5vh;
            font-weight: 500;
        }
    }

    .wrap-calendar {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    // 미디어 쿼리를 사용하여 화면 너비에 따른 스케일 조정
    @media (max-width: 600px) {
        .container {
            .wrap-header {
                .txt-date {
                    font-size: 2vh; // 화면이 좁아질 때 글꼴 크기를 줄임
                    margin-bottom: 5px;
                }

                .txt-time {
                    font-size: 1 ; // 화면이 좁아질 때 글꼴 크기를 줄임
                }
            }

            .wrap-calendar {
                transform: scale(1.5);
            }
        }
    }
}
