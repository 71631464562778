.wrap-accordion {
    margin-bottom: 10px;
    .wrap-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        background-color: var(--lime);
        border: 1px solid var(--brown);
        border-radius: 10px;
        padding: 5px;
        .ico-arrow-down {
            width: 30px;
            height: 30px;
        }
    }
    .wrap-content {
        display: none;
    }
}

.wrap-accordion.open {
    .wrap-header {
        .ico-arrow-down {
            transform: rotate(-180deg);
        }
    }
    .wrap-content {
        display: block;
    }
}