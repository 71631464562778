@import "@scss/utils.scss";

.container {
    @include txt-content;
    .ico-post {
        width: 10vh;
        height: 10vh;
        margin-top: 5vh;
        margin-bottom: 2vh;
        opacity: 0.8;
    }
}