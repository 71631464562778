@import "@scss/utils.scss";

.container {
    @include txt-content;
    position: relative;
    .wrap-title {
        position: absolute;
        left: 50%;
        top: 54%;
        transform: translate(-50%, -50%);
        border: 2px solid var(--black);
        background-color: rgba(245,235,230,0.7);
        padding: 3px;
        .title-eng {
            position: relative;
            font-size: 15px;
        }
        .title-kor {
            font-size: 20px;
            font-weight: bold;
            font-family: 'BinggraeSamanco';
            margin-left: 4px;
            letter-spacing: 8px;
            text-align: center;
            display: block;
            width: 100%;
        }
    }
    .wrap-image {
        width: 100%;
        height: 300px;
        justify-content: center;
        background: url('../../asset/images/intro_main.png') center/cover no-repeat;
    }
    .wrap-people {
        font-size: 2vh;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.5vh;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 2vh;

        .ico-heart {
            fill: var(--pink);
            width: 2vh;
            height: 2vh;
            margin-left: 1vh;
            margin-right: 1.5vh;
        }

        
    }
    .ico-flower {
        width: 10vh;
        height: 10vh;
        margin-bottom: 2vh;
    }

    .wrap-location {
        font-size: 15px;
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
    }

    .wrap-text {
        font-size: 2vh;
        font-weight: bold;
    }
    
}