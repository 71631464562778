.container {
    padding: 24px;

    .txt-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 24px;
    }
}

