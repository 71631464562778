    .container {
        .wrap-contact {
            display: flex;
            padding: 1vh;
            .wrap-contact-info {
                display: flex;
                flex: 1;
                flex-direction: column;
                line-height: 1.6;
                font-family: 'Groce';
                .contact-name {
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
                .contact-bank-name {
                    font-size: 14px;
                    color: var(--brown);
                }
                .contact-account {
                    font-size: 13px;
                    color: var(--gray);
                    font-weight: 800;
                }
            }
            .wrap-icons {
                font-family: 'Groce';
                font-size: 11px;
                .wrap-icon {
                    display: inline-block;
                    display: flex;
                    height: 23px;
                    width: 100px;
                    flex-direction: row;
                    padding: 3px;
                    justify-content: center;
                    text-align: center;
                    border: 1px solid var(--black);
                    background-color: #fff;
                    border-radius: 1vh;
                    margin-bottom: 3px;
                    span {
                        display: block;
                        text-align: center;
                        justify-content: center;
                    }
                    .button {
                        width: 20px;
                        height: 20px;
                        margin-right: 1.8vh;
                    }
                }
            }
        }
}