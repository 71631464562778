.dimmed {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000;
    z-index: 9999;

    .icon-close {
        width: 3vh;
        height: 3vh;
        fill: #fff;
        position: absolute;
        top: 3%;
        right: 3%;
        z-index: 2;
    }
}