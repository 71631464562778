.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ico-heart {
    fill: var(--red);
    width: 10vh;
    height: 10vh;
    animation: heart 1.2s infinite linear;
}

@keyframes heart {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.05);
    }
    40% {
        transform: scale(0.9);
    }
    60% {
        transform: scale(1.1);
    }
    80% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }
}

.ico-error {
    fill: var(--black);
    width: 10vh;
    height: 10vh;
    animation: heart 1.2s infinite linear;
}