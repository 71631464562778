.container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wrap-guestbook {
    display: flex;
    flex-direction: column;
    align-items: center;

    // 간격 추가
    margin-bottom: 2vh;

    .wrap-table {
      width: 100%;
          
      .message {
        padding-bottom: 10px;
    
        .author-date {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid var(--pink);
          margin-bottom: 10px;
          padding-bottom: 3px;
    
          .author {
            font-size: 20px;
            margin-right: 5px;
            padding: 3px;
            color: var(--black);
          }
          .role {
            font-size: 16px;
            border: 1px solid var(--brown);
            padding: 3px;
            color: var(--beige);
            background-color: var(--pink);
            transition: all .5s ease;
          }
          
          .role:hover {
            background-color: var(--gold);
          }
    
          .date {
            margin-left: auto;
            padding-top: 3px;
            font-size: 15px;
            margin-top: 8px;
            color: var(--brown);
            
          }
        }
        .content {
          font-size: 12px;
          min-height: 60px;
          font-family: sans-serif;
          padding: 5px;
          background-color: #fff;
          border: 1px solid var(--yellow);
        }
      }
    }

    .pagination {
      width: 100%; // 너비 100%로 설정
      margin-top: 10px;
      margin-bottom: 30px;

      ul {
        padding: 0;
        margin: 0;
        text-align: center; // 중앙 정렬
        display: flex;
        justify-content: space-between; // 양 끝에 붙이기

        li {
          display: inline-block; // 가로로 펼치기
          margin: 0 1vh; // 각 항목 사이의 간격 조절
        }
      }
    }
    .txt-title {
      margin-top: 20px;
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 24px;
    }
    .wrap-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    
      form {
        /* Remove display: inline-block; */
        align-items: center;
        margin-bottom: 2vh;
        width: 80%;
    
        .wrap-name {
          display: flex;
          justify-content: space-between;
          padding: 3px;
    
          input[name="name"] {
            width: 65%; /* Set width to 100% */
            margin-bottom: 10px;

            padding: 3px;
          }
          .spacing {
            width: 5%;
          }
    
          select[name="role"] {
            width: 30%; /* Set width to 100% */
            margin-bottom: 10px;
            padding: 3px;
          }
        }
    
        textarea[name="message"] {
          width: 96%;
          height: 180px;
          margin-left: 0.5%;
          margin-bottom: 1vh;
          padding: 10px;
          border-radius: 10px;
        }
    
        .wrap-submitBtn {
          display: flex;
          justify-content: flex-end; /* 자식 요소를 우측으로 정렬 */
          width: 100%; /* 부모 컨테이너의 너비를 100%로 설정 */
          
          button {
            font-size: 2vh;
            margin-right: 5px;
            border: 1px solid var(--brown);
            padding: 3px 20px;
            border-radius: 10px;
            background-color: #fff;
          }
        }
      }
    }
  }
