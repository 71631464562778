@charset "utf-8";
@import url("./reset.scss");
@import url("./font.scss");

:root {
    --red: #ea7664;
    --black: #544f4f;
    --brown: #89757a;
    --gray: #76777c;
    --beige: #f6f5f5;
    --pink: #ff4ba6;
    --orange: #ff7e62;
    --gold: #ffb94d;
    --yellow: #efe670;
    --lime: #bfffa4;
}

body {
    font-family: "BinggraeSamanco", "Groce", sans-serif;
}