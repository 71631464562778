.container {
    .wrap-share {
        display: flex;
        justify-content: center;
            svg {
                width: 5vh;
                height: 5vh;     
                margin-right: 2vh;
            }
    }
}