@font-face {
    font-family: 'Typo_CrayonM';
    src:
        url("../asset/Typo_CrayonM.ttf") format("truetype");
        unicode-range:U+AC00-D7A3;
}

@font-face {
    font-family: 'NanumGyuRiEuiIrGi';
    src:
        url("../asset/NanumGyuRiEuiIrGi.woff2") format("woff2"),
        url("../asset/NanumGyuRiEuiIrGi.woff") format("woff"),
        url("../asset/NanumGyuRiEuiIrGi.ttf") format("truetype"),
}

@font-face {
    font-family: 'Groce';
    src:
        url("../asset/Groce.otf") format("opentype"),
        url("../asset/Groce.ttf") format("truetype");
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;
}

@font-face {
    font-family: 'BinggraeSamanco';
    src:
        url('../asset/BinggraeSamanco.otf') format("opentype"),
        url('../asset/BinggraeSamanco.ttf') format("truetype");
}